import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Contacto = () => {
  const [contacto, setContacto] = useState({
    nombre: '',
    email: '',
    asunto: '',
    mensaje: '',
  });

  const handleChange = (e) => {
    setContacto({ ...contacto, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://ironsafe-proyecto.h43drn.easypanel.host/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contacto),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error en la respuesta del servidor: ${response.statusText}`);
      }
      return response.text();
    })
    .then(data => {
      alert(data); 
      setContacto({
        nombre: '',
        email: '',
        asunto: '',
        mensaje: '',
      });
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Hubo un error al enviar el mensaje, inténtelo de nuevo.');
    });
    
  };

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 21.15602693524033,
    lng: -98.38116826218524,
  };

  return (
    <div className="contacto-layout">
      <LoadScript googleMapsApiKey="AIzaSyDoSeSsBjVj4C_7bn9HyXFI25Wu77maIso">
        <div className="map-container">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={15}
          >
            <Marker position={center} />
          </GoogleMap>
        </div>
      </LoadScript>
      <div className="formulario-contacto">
        <h1>Contacto</h1>
        <h3>Comunícate con nosotros o ven a nuestra sucursal</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Nombre:</label>
            <input
              type="text"
              name="nombre"
              value={contacto.nombre}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={contacto.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Asunto:</label>
            <input
              type="text"
              name="asunto"
              value={contacto.asunto}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Mensaje:</label>
            <textarea
              name="mensaje"
              value={contacto.mensaje}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Contacto;
