import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function MandarCorreo() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); 

  const solicitarRestablecimiento = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/solicitar-recuperacion', { correo: email });
      console.log(response.data);
      Swal.fire({
        icon: 'success',
        title: '¡Solicitud enviada!',
        text: 'Si tu correo está registrado, recibirás un código para verificar tu cuenta.',
        confirmButtonText: 'Entendido'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/CodigoPregunta', { state: { email } });
        }
      });
    } catch (error) {
      console.error('Error al solicitar la recuperación de contraseña', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al solicitar la recuperación de contraseña.',
        confirmButtonText: 'Intentar de nuevo'
      });
    }
  };

  return (
    <div className="container">
      <h1>Ingresa tu email</h1>
      <form onSubmit={solicitarRestablecimiento}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Solicitar Restablecimiento</button>
      </form>
    </div>
  );
}
