import React, { useState } from 'react';
import axios from 'axios';

const AgregarUsuario = ({ onCancelar, onAgregar }) => {
  const [nombre, setNombre] = useState('');
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [correo, setEmail] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [confirmarContrasena, setConfirmarContrasena] = useState('');
  const [tipo, setTipo] = useState('Cliente');

  const validarNombre = (nombre) => nombre.length >= 2;
  const validarUsuario = (usuario) => usuario.length >= 2;
  const validarEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validarContrasena = (password) => {
    let errores = [];
    if (password.length < 8) errores.push("al menos 8 caracteres");
    if (!/[A-Z]/.test(password)) errores.push("una mayúscula");
    if (!/\d/.test(password)) errores.push("un número");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errores.push("un símbolo especial (@, #, $, etc.)");
    return errores;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validaciones
    if (!validarNombre(nombre)) {
      alert('El nombre debe tener al menos 2 caracteres.');
      return;
    }
    if (!validarUsuario(nombreUsuario)) {
      alert('El nombre de usuario debe tener al menos 2 caracteres.');
      return;
    }
    if (!validarEmail(correo)) {
      alert('Ingrese un correo electrónico válido.');
      return;
    }

    const erroresContrasena = validarContrasena(contrasena);
    if (erroresContrasena.length > 0) {
      alert(`La contraseña debe tener ${erroresContrasena.join(", ")}.`);
      return;
    }

    if (contrasena !== confirmarContrasena) {
      alert('Las contraseñas no coinciden.');
      return;
    }

    // Crear usuario
    axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/registro', {
      nombre: nombre,
      nombre_usuario: nombreUsuario,
      correo: correo,
      contraseña: contrasena,
      tipo: tipo
    })
      .then(() => {
        alert('Usuario agregado exitosamente.');
        onAgregar();
      })
      .catch((error) => {
        console.error('Error al agregar usuario:', error);
        alert('Error al agregar el usuario. Por favor, intente de nuevo más tarde.');
      });
  };

  return (
    <div className="container">
      <h1>Agregar Usuario</h1>
      <form onSubmit={handleSubmit}>
        <label>Nombre:</label>
        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />

        <label>Nombre de Usuario:</label>
        <input type="text" value={nombreUsuario} onChange={(e) => setNombreUsuario(e.target.value)} required />

        <label>Correo Electrónico:</label>
        <input type="email" value={correo} onChange={(e) => setEmail(e.target.value)} required />

        <label>Contraseña:</label>
        <input type="password" value={contrasena} onChange={(e) => setContrasena(e.target.value)} required />

        <label>Confirmar Contraseña:</label>
        <input type="password" value={confirmarContrasena} onChange={(e) => setConfirmarContrasena(e.target.value)} required />

        <label>Tipo:</label>
        <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
          <option value="cliente">Cliente</option>
          <option value="administrador">Administrador</option>
        </select>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            <button type="submit">Agregar</button>
          </div>
          <div>
            <button type="button" onClick={onCancelar}>Cancelar</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AgregarUsuario;
