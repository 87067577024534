import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PerfilUsuario = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [usuario, setUsuario] = useState({
    nombre: '',
    nombreUsuario: '',
    correo: '',
    contrasena: '',
    confirmarContrasena: '',
  });
  const [errores, setErrores] = useState({});
  const [mostrarContrasena, setMostrarContrasena] = useState(false);
  const [mostrarConfirmarContrasena, setMostrarConfirmarContrasena] = useState(false);

  useEffect(() => {
    if (currentUser?.correo) {
      const cargarDatosUsuario = async () => {
        try {
          const respuesta = await axios.get(`https://ironsafe-proyecto.h43drn.easypanel.host/usuarios/${currentUser.correo}`);
          setUsuario({
            id: currentUser.id,
            nombre: respuesta.data.nombre,
            nombreUsuario: respuesta.data.nombre_usuario,
            correo: respuesta.data.correo,
            contrasena: '',
            confirmarContrasena: '',
          });
        } catch (error) {
          console.error('Error al cargar los datos del usuario:', error);
        }
      };
      cargarDatosUsuario();
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario(prev => ({ ...prev, [name]: value }));
    validarCampo(name, value);
  };

  const validarCampo = (campo, valor) => {
    let mensajeError = '';
    switch (campo) {
      case 'nombre':
        mensajeError = valor.trim().length >= 2 ? '' : 'El nombre debe tener al menos 2 caracteres.';
        break;
      case 'nombreUsuario':
        mensajeError = valor.trim().length >= 2 ? '' : 'El usuario debe tener al menos 2 caracteres.';
        break;
      case 'contrasena':
        break;
      case 'confirmarContrasena':
        mensajeError = valor === usuario.contrasena ? '' : 'Las contraseñas no coinciden.';
        break;
      default:
        break;
    }
    setErrores(prev => ({ ...prev, [campo]: mensajeError }));
  };

  const actualizarPerfil = async (e) => {
    e.preventDefault();
    if (Object.keys(errores).every((key) => !errores[key])) {
      try {
        const { id, nombre, nombreUsuario, contrasena } = usuario;
        const respuesta = await axios.put(`https://ironsafe-proyecto.h43drn.easypanel.host/usuarios/${id}`, {
          nombre,
          nombreUsuario,
          contrasena,
        });

        if (respuesta.status === 200) {
          Swal.fire('¡Actualización exitosa!', 'Tu perfil ha sido actualizado.', 'success')
            .then(() => navigate('/perfil'));
        } else {
          throw new Error('Error al actualizar el perfil');
        }
      } catch (error) {
        Swal.fire('Error al actualizar el perfil', 'Inténtalo de nuevo más tarde.', 'error');
        console.error('Error al actualizar el perfil:', error);
      }
    } else {
      Swal.fire('Error', 'Por favor, corrige los errores antes de actualizar tu perfil.', 'error');
    }
  };

  return (
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'left' }}>
      <h2>Actualizar Perfil</h2>
      <form onSubmit={actualizarPerfil}>
        <div style={{ marginBottom: '20px' }}>
          <label>Nombre:</label>
          <input type="text" name="nombre" value={usuario.nombre} onChange={handleChange} required />
          {errores.nombre && <p style={{ color: 'red' }}>{errores.nombre}</p>}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <label>Nombre de Usuario:</label>
          <input type="text" name="nombreUsuario" value={usuario.nombreUsuario} onChange={handleChange} required />
          {errores.nombreUsuario && <p style={{ color: 'red' }}>{errores.nombreUsuario}</p>}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <label>Correo Electrónico:</label>
          <input type="email" name="correo" value={usuario.correo} onChange={handleChange} disabled />
        </div>

        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label>Contraseña:</label>
          <input
            type={mostrarContrasena ? "text" : "password"}
            name="contrasena"
            value={usuario.contrasena}
            onChange={handleChange}
          />
          <button type="button" onClick={() => setMostrarContrasena(!mostrarContrasena)} style={{ position: 'absolute', right: '10px', top: '35px', cursor: 'pointer', background: 'none', border: 'none' }}>
            {mostrarContrasena ? "Ocultar" : "Mostrar"}
          </button>
          {errores.contrasena && <p style={{ color: 'red' }}>{errores.contrasena}</p>}
        </div>
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label>Confirmar Contraseña:</label>
          <input
            type={mostrarConfirmarContrasena ? "text" : "password"}
            name="confirmarContrasena"
            value={usuario.confirmarContrasena}
            onChange={handleChange}
          />
          <button type="button" onClick={() => setMostrarConfirmarContrasena(!mostrarConfirmarContrasena)} style={{ position: 'absolute', right: '10px', top: '35px', cursor: 'pointer', background: 'none', border: 'none' }}>
            {mostrarConfirmarContrasena ? "Ocultar" : "Mostrar"}
          </button>
          {errores.confirmarContrasena && <p style={{ color: 'red' }}>{errores.confirmarContrasena}</p>}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label>Pin:</label>
          <input type="number" name="nombreUsuario" value={usuario.nombreUsuario} onChange={handleChange} required />
          {errores.nombreUsuario && <p style={{ color: 'red' }}>{errores.nombreUsuario}</p>}
        </div>
        
        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Actualizar
        </button>
      </form>
    </div>
  );
};
export default PerfilUsuario;
