import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import Swal from 'sweetalert2';

export default function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [mostrarContrasena, setMostrarContrasena] = useState(false);

  const verificarCredenciales = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/login', {
        nombre_usuario: usuario,
        contraseña: contrasena,
      });
  
      if (response.data.message === "Usuario encontrado") {
        login({
          usuario: usuario,
          id: response.data.id,
          tipo: response.data.tipo,
          correo: response.data.correo,
          codigo: response.data.codigo
        }, response.data.id, response.data.mac,response.data.codigo );
  
        Swal.fire('¡Bienvenido!', 'Inicio de sesión exitoso.', 'success')
          .then((result) => {
            if (response.data.tipo === 'administrador') {
              navigate('/bienvenidaAdmin');
            } else {
              navigate('/bienvenidaCliente');
            }
          });
      } else {
        Swal.fire('Error', 'Usuario no encontrado o contraseña incorrecta', 'error');
      }
    } catch (error) {
      Swal.fire('Error en el login', 'Ocurrió un error desconocido', 'error');
    }
  };
  
  const toggleMostrarContrasena = () => {
    setMostrarContrasena(!mostrarContrasena);
  };

  return (
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <img
        src={require("../assets/CANDADOS (28).png")}
        className="logo"
        alt="logo"
        style={{ width: '90px' }}
      />
      <h1 className="titulo" style={{ margin: '20px 0' }}>IRON SAFE</h1>
      <form onSubmit={verificarCredenciales} style={{ width: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          <label className="textoCajas" style={{ display: 'block' }}>Usuario:</label>
          <input
            className="cajas"
            type="text"
            name="usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label className="textoCajas" style={{ display: 'block' }}>Contraseña:</label>
          <input
            className="cajas"
            type={mostrarContrasena ? "text" : "password"}
            name="contrasena"
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }} // Adjust for padding
          />
          <button type="button" onClick={toggleMostrarContrasena} style={{ position: 'absolute', right: '10px', top: '45px', cursor: 'pointer', background: 'none', border: 'none', padding: '10px' , color:'green'}}>
            {mostrarContrasena ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <button className="boton" type="submit" style={{ width: '100%', padding: '10px', background: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Iniciar Sesión
        </button>
      </form>
      <button className="boton" onClick={() => navigate('/registro')} style={{ display: 'block', width: '100%', marginTop: '20px', padding: '10px', background: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Registrarse</button>
      <Link to="/opciones" className="enlaceRecuperacion" style={{ display: 'block', marginTop: '20px' }}>¿Desea recuperar su contraseña?</Link>
    </div>
  );
}
