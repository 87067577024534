import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function NuevaContrasena() {
  const [nuevaContrasena, setNuevaContrasena] = useState('');
  const [confirmarContrasena, setConfirmarContrasena] = useState('');
  const [mostrarNuevaContrasena, setMostrarNuevaContrasena] = useState(false);
  const [mostrarConfirmarContrasena, setMostrarConfirmarContrasena] = useState(false);
  const [erroresContrasena, setErroresContrasena] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  

  useEffect(() => {
    if (!email) {
      navigate('/recuperar-contrasena');
    }
  }, [email, navigate]);

  useEffect(() => {
    setErroresContrasena(validarContrasena(nuevaContrasena));
  }, [nuevaContrasena]);

  const validarContrasena = (password) => {
    let errores = [];
    if (password.length < 8) errores.push("Debe tener al menos 8 caracteres.");
    if (!/[A-Z]/.test(password)) errores.push("Debe incluir al menos una letra mayúscula.");
    if (!/\d/.test(password)) errores.push("Debe contener al menos un número.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errores.push("Debe tener al menos un símbolo especial (@, #, $, etc.)");
    return errores;
  };

  const restablecerContrasena = async (e) => {
    e.preventDefault();

    if (erroresContrasena.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error en la contraseña',
        text: `La contraseña debe ${erroresContrasena.join(", ")}.`,
        confirmButtonText: 'Entendido'
      });
      return;
    }

    if (nuevaContrasena !== confirmarContrasena) {
      Swal.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden',
        confirmButtonText: 'Intentar de nuevo'
      });
      return;
    }

    try {
      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/cambiar-contrasena', {
        correo: email,
        nuevaContrasena: nuevaContrasena,
      });
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Contraseña actualizada con éxito',
          confirmButtonText: 'Iniciar sesión'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/login');
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al actualizar la contraseña',
        text: `${error.response ? error.response.data : 'No se pudo conectar al servidor'}`,
        confirmButtonText: 'Intentar de nuevo'
      });
    }
  };


  return (
    <div className="container" style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
      <h2>Restablecer Contraseña</h2>
      <form onSubmit={restablecerContrasena}>
      {erroresContrasena.length > 0 && (
          <ul style={{ color: 'red' }}>
            {erroresContrasena.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label>Nueva Contraseña:</label>
          <input
            type={mostrarNuevaContrasena ? "text" : "password"}
            value={nuevaContrasena}
            onChange={(e) => setNuevaContrasena(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', paddingRight: '50px' }}
          />
          <button
            onClick={() => setMostrarNuevaContrasena(!mostrarNuevaContrasena)}
            type="button"
            style={{ position: 'absolute', right: '10px', top: '32px', cursor: 'pointer', background: 'transparent', border: 'none', color: 'grey', padding: '5px' }}>
            {mostrarNuevaContrasena ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label>Confirmar Nueva Contraseña:</label>
          <input
            type={mostrarConfirmarContrasena ? "text" : "password"}
            value={confirmarContrasena}
            onChange={(e) => setConfirmarContrasena(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', paddingRight: '50px' }}
          />
          <button
            onClick={() => setMostrarConfirmarContrasena(!mostrarConfirmarContrasena)}
            type="button"
            style={{ position: 'absolute', right: '10px', top: '32px', cursor: 'pointer', background: 'transparent', border: 'none', color: 'grey', padding: '5px' }}>
            {mostrarConfirmarContrasena ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <button
          type="submit"
          style={{ width: '100%', padding: '10px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Restablecer Contraseña
        </button>
      </form>
    </div>
  );
}
