import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      return parsedAuthData.currentUser;
    } else {
      return null;
    }
  });

  const [userid, setUserid] = useState(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      return parsedAuthData.userid;
    } else {
      return null;
    }
  });

  const [userMac, setUserMac] = useState(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      return parsedAuthData.userMac;
    } else {
      return null;
    }
  });

  const [userCodigo, setUserCodigo] = useState(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      return parsedAuthData.userCodigo;
    } else {
      return null;
    }
  });

  const login = (userData, _id, mac, codigo) => {
    setCurrentUser({
      ...userData,
      id: _id,
      correo: userData.correo
    });
    setUserid(_id);
    setUserMac(mac);
    setUserCodigo(codigo);

    // Guardar datos de autenticación en el almacenamiento local
    const authData = {
      currentUser: userData,
      userid: _id,
      userMac: mac,
      userCodigo: codigo
    };
    localStorage.setItem('authData', JSON.stringify(authData));
  };

  const logout = () => {
    setCurrentUser(null);
    setUserMac(null);
    setUserid(null);
    setUserCodigo(null);
    localStorage.removeItem('authData');
  };

  const value = {
    currentUser,
    userMac,
    userid,
    userCodigo,
    updateUserMac: setUserMac,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
