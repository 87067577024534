import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../Administrador/Modal';
import { useAuth } from '../AuthContext';
import Swal from 'sweetalert2';

export default function Estadistica() {
  const [sensores, setSensores] = useState([]);
  const [estadoPuerta, setEstadoPuerta] = useState('Desconocido');
  const [mostrarModal, setMostrarModal] = useState(false);
  const [pin, setPin] = useState('');
  const { userMac, userid, userCodigo } = useAuth();
  const [pinRegistrado, setPinRegistrado] = useState(false);
  const [showPinAlert] = useState(false);
  const [codigo, setCodigoProducto] = useState(userCodigo || '');

  useEffect(() => {
    const cargarDatosSensores = async () => {
      try {
        const respuesta = await axios.get('https://ironsafe-proyecto.h43drn.easypanel.host/estado-sensores', {
          params: {
            userId: userid
          }
        });
        setSensores(respuesta.data);
        const sensorPuerta = respuesta.data.find(sensor => sensor.sensor === 'Puerta');
        if (sensorPuerta) {
          setEstadoPuerta(sensorPuerta.dato);
        }
      } catch (error) {
        console.error('Error al obtener datos de sensores:', error);
      }
    };
    

    cargarDatosSensores();
    const intervalId = setInterval(cargarDatosSensores, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const verificarPinRegistrado = async () => {
      try {
        const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/registro-pin', {
          producto: userCodigo,
        });
        if (response.data.message === "Pin encontrado") {
          setPinRegistrado(true);
        } else {
          setPinRegistrado(false);
        }
      } catch (error) {
        console.error('Error al verificar PIN:', error);
      }
    };

    verificarPinRegistrado();
  }, [userCodigo]);

  useEffect(() => {
    if (!userCodigo) {
      mostrarAlertaCodigoProducto();
    }
  }, [userCodigo]);


  useEffect(() => {
    const verificarPinRegistrado = async () => {
      try {
        const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/registro-pin', {
          producto: userCodigo,
        });
        if (response.data.message === "Pin encontrado") {
          setPinRegistrado(true);
        } else {
          setPinRegistrado(false);
        }
      } catch (error) {
        console.error('Error al verificar PIN:', error);
      }
    };

    verificarPinRegistrado();
  }, [userMac, userCodigo]);


  const mostrarAlertaCodigoProducto = async () => {
    try {
      if (!userCodigo) {
        const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/verificar-producto', {
          userid: userid
        });
        if (!response.data.success) {
          Swal.fire({
            icon: 'info',
            title: 'No tiene registrado un producto, por favor asigne uno',
            html:
              '<input id="codigoProducto" class="swal2-input" placeholder="Introduce el código del producto">',
            focusConfirm: false,
            preConfirm: () => {
              return document.getElementById('codigoProducto').value;
            }
          }).then((result) => {
            if (result.isConfirmed) {
              const codigoProducto = document.getElementById('codigoProducto').value;
              if (codigoProducto) {
                setCodigoProducto(codigoProducto);
                asignarProducto(codigoProducto);
              }
            }
          });
        }
      }
    } catch (error) {
      console.error('Error al verificar código de producto:', error);
    }
  };



  const abrirModal = async () => {
    const tieneProductoConPin = await verificarProductoConPin();

    if (tieneProductoConPin) {
      setMostrarModal(true);
    } else {
      const confirmacion = await Swal.fire({
        icon: 'question',
        title: 'No tiene un PIN, ¿Desea registrarlo?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      });

      if (!confirmacion.isConfirmed) {
        return;
      }

      Swal.fire({
        title: 'Ingresa tu PIN',
        input: 'number',
        inputClass: 'swal-input-pin',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        preConfirm: async (pin) => {
          try {
            const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/asignar-pin', {
              pin: pin,
              producto: codigo
            });
            if (response.data.message === "PIN registrado exitosamente") {
              Swal.fire({
                icon: 'success',
                title: 'PIN registrado',
                text: 'El PIN se ha registrado correctamente.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al registrar el PIN. Por favor, intenta de nuevo.',
              });
            }
          } catch (error) {
            console.error('Error al enviar PIN:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error al registrar el PIN. Por favor, intenta de nuevo.',
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  };

  // Función para verificar si hay un producto con PIN registrado
  const verificarProductoConPin = async () => {
    try {
      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/verificar-producto-pin', {
        userId: userid,
      });
      return response.data.success;
    } catch (error) {
      console.error('Error al verificar producto con PIN:', error);
      return false;
    }
  };



  const cerrarModal = () => setMostrarModal(false);

  const actualizarPin = (e) => setPin(e.target.value);

  const enviarPin = async () => {
    try {
      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/verificar-mqtt', {
        userId: userid,
        pin: pin,
        producto: codigo,
      });
      if (response.data.message === "Pin encontrado") {
        Swal.fire({
          icon: 'success',
          title: 'PIN correcto',
          text: 'La caja se ha abierto.',
        });
        cerrarModal();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'PIN incorrecto.',
        });
      }
    } catch (error) {
      console.error('Error al enviar PIN:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al verificar el PIN. Por favor, intenta de nuevo.',
      });
    }
  };

  const asignarProducto = async (codigoProducto) => {
    try {
      if (!codigoProducto) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor ingresa un código de producto.',
        });
        return;
      }

      if (!userid) {
        console.error('No se pudo obtener el ID de usuario');
        return;
      }

      const confirmacion = await Swal.fire({
        icon: 'question',
        title: '¿Seguro que desea asignar este producto?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      });

      if (!confirmacion.isConfirmed) {
        return;
      }


      const response = await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/asignar-producto', {
        userId: userid,
        codigo: codigoProducto
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Producto asignado',
          text: 'El producto se ha asignado correctamente.',
        });
        setCodigoProducto(codigoProducto);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Error al asignar el producto. Por favor, intenta de nuevo.',
        });
      }
    } catch (error) {
      console.error('Error al asignar producto:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al asignar el producto. Por favor, intenta de nuevo.',
      });
    }
  };



  function IndicadorLED({ estado }) {
    const estiloLED = {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: estado === 'Movimiento Detectado' ? 'red' : 'green',
      margin: '0 auto',
      display: 'block',
    };

    const claseLED = estado === 'Movimiento Detectado' ? 'ledParpadeo' : '';

    return <div style={estiloLED} className={claseLED}></div>;
  }

  function IndicadorEstadoPuerta() {
    const iconoPuerta = estadoPuerta === 'Abierta' ? require("../../assets/puerta_abierta.png") : require("../../assets/puerta_cerrada.png");
    const altTexto = estadoPuerta === 'Abierta' ? "Puerta Abierta" : "Puerta Cerrada";
    return (
      <div>
        <img src={iconoPuerta} alt={altTexto} style={{ width: 100, height: 100 }} />
        <p>La puerta está: {estadoPuerta}</p>
      </div>
    );
  }

  return (
    <div className="safeArea">
      <div className="container">
        <h1 className="titulo">Caja Fuerte</h1>
        <p className="textoCajas">Estadísticas del dispositivo</p>

        <div className="codigoProducto">
          <p>Codigo del dispositivo</p>
          <div className="inputContainer">
            <input
              id="codigoProductoInput"
              type="text"
              value={codigo}
              onChange={(e) => setCodigoProducto(e.target.value)}
              style={{ width: '80%', marginBottom: '5px', marginRight: '5px' }}
            />

            <button onClick={() => asignarProducto(document.getElementById('codigoProductoInput').value.toString())}>
              Asignar
            </button>

          </div>
          {(!userMac && !codigo) && (
            <p style={{ color: 'red', marginBottom: '10px' }}>No tienes ningun producto registrada. Por favor, ingresa un código de producto para vincularlo.</p>
          )}
        </div>

        <div className="dashboard">
          <div className="estadoPuerta">
            <h3>Estado de la Puerta</h3>
            <IndicadorEstadoPuerta />
          </div>

          <div className="estadosSensores">
            {sensores.filter(sensor => sensor.sensor !== 'Puerta').map((sensor, index) => (
              <div key={index} className="widget">
                <h3>{sensor.sensor}</h3>
                <IndicadorLED estado={sensor.dato} />
                <p>Estado: {sensor.dato}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="botonera">
          <button className="botonVerde" onClick={abrirModal}>Abrir Caja</button>
        </div>

        {mostrarModal && (
          <Modal onClose={cerrarModal}>
            <div>
              {showPinAlert && (
                <div>
                  <p>No hay un PIN registrado. Por favor, registra un PIN primero.</p>
                </div>
              )}
              {pinRegistrado && (
                <input
                  type="number"
                  value={pin}
                  onChange={actualizarPin}
                  placeholder="Introduce el PIN"
                  style={{ width: '100%' }}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button onClick={enviarPin}>Enviar</button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
