import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AgregarUsuario from './AgregarUsuario';
import Modal from './Modal'; // Asegúrate de que este es el path correcto al componente Modal
import Swal from 'sweetalert2';
import ActualizarUsuario from './ActualizarUsuario'; // Asegúrate de importar correctamente tu nuevo componente

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [mostrarAgregar, setMostrarAgregar] = useState(false);
  const [usuarioDetalle, setUsuarioDetalle] = useState(null);
  const [usuarioEditar, setUsuarioEditar] = useState(null);
  const [mostrarModalMacs, setMostrarModalMacs] = useState(false);
  const [usuarioParaAsignar, setUsuarioParaAsignar] = useState(null);
  const [codigosDisponibles, setCodigosDisponibles] = useState([]); // Cambiado a codigosDisponibles

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    try {
      const response = await axios.get('https://ironsafe-proyecto.h43drn.easypanel.host/usuarios');
      setUsuarios(response.data);
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  };

  const eliminarUsuario = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://ironsafe-proyecto.h43drn.easypanel.host/usuarios/${id}`);
          Swal.fire('Eliminado!', 'El usuario ha sido eliminado.', 'success');
          fetchUsuarios(); // Refresca la lista de usuarios después de eliminar
        } catch (error) {
          Swal.fire('Error', 'Error al eliminar el usuario', 'error');
        }
      }
    });
  };

  const actualizarUsuario = async (id, datosUsuario) => {
    try {
      await axios.put(`https://ironsafe-proyecto.h43drn.easypanel.host/usuarios/${id}`, datosUsuario);
      Swal.fire('¡Actualizado!', 'El usuario ha sido actualizado exitosamente.', 'success');
      fetchUsuarios();
      setUsuarioEditar(null);
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
  };

  const cargarCodigosDisponibles = async () => { // Cambiado a cargarCodigosDisponibles
    try {
      const response = await axios.get('https://ironsafe-proyecto.h43drn.easypanel.host/codigos-disponibles'); // Cambiado a codigos-disponibles
      setCodigosDisponibles(response.data); // Cambiado a codigosDisponibles
    } catch (error) {
      console.error('Error al cargar códigos disponibles:', error); // Cambiado a códigos disponibles
    }
  };

  const iniciarAsignacionCodigo = (userId) => { // Cambiado a iniciarAsignacionCodigo
    setUsuarioParaAsignar(userId);
    cargarCodigosDisponibles();
    setMostrarModalMacs(true);
  };

  const asignarCodigoSeleccionado = async (codigo) => { // Cambiado a asignarCodigoSeleccionado
    if (usuarioParaAsignar && codigo) {
      try {
        await axios.post('https://ironsafe-proyecto.h43drn.easypanel.host/asignar-codigo', { userId: usuarioParaAsignar, codigo });
        Swal.fire('Asignada!', 'Producto asignado con éxito.', 'success'); // Cambiado a Producto asignado
        setMostrarModalMacs(false);
        cargarCodigosDisponibles(); // Cambiado a cargarCodigosDisponibles
        fetchUsuarios();
      } catch (error) {
        Swal.fire('Error', 'Error al asignar código.', 'error'); // Cambiado a código
      }
    }
  };

  const mostrarDetalle = (usuario) => {
    setUsuarioDetalle(usuario);
  };

  const mostrarActualizar = (usuario) => {
    setUsuarioEditar(usuario);
  };

  if (mostrarAgregar) {
    return <AgregarUsuario onCancelar={() => setMostrarAgregar(false)} onAgregar={() => {
      setMostrarAgregar(false);
      fetchUsuarios();
    }} />;
  }

  if (usuarioEditar) {
    return <ActualizarUsuario
      usuario={usuarioEditar}
      onActualizarUsuario={(datosUsuario) => actualizarUsuario(usuarioEditar._id, datosUsuario)}
      onCancelar={() => setUsuarioEditar(null)}
    />;
  }

  return (
    <div>
      <table className="table table-dark">
        <thead>
          <h2>Usuarios</h2>
          <button onClick={() => setMostrarAgregar(true)}>Agregar Usuario</button>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Producto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map(usuario => (
            <tr key={usuario._id}>
              <td>{usuario.nombre}</td>
              <td>{usuario.correo}</td>
              <td>
                {usuario.dispositivo.map((dispositivo, index) => (
                  <span key={index}>
                    {dispositivo.producto}
                    {index < usuario.dispositivo.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </td>
              <td>
                <button style={{ marginRight: '10px' }} onClick={() => iniciarAsignacionCodigo(usuario._id)}>Asignar Producto</button> {/* Cambiado a iniciarAsignacionCodigo */}
                <button style={{ marginRight: '10px' }} onClick={() => mostrarDetalle(usuario)}>Ver más</button>
                <button style={{ marginRight: '10px' }} onClick={() => mostrarActualizar(usuario)}>Actualizar</button>
                <button onClick={() => eliminarUsuario(usuario._id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>

      </table>
      {usuarioDetalle && (
        <Modal onClose={() => setUsuarioDetalle(null)}>
          <p>Usuario: {usuarioDetalle.nombre_usuario}</p>
          <p>Contraseña: {usuarioDetalle.contraseña}</p>
          <p>Tipo: {usuarioDetalle.tipo}</p>
          <p>Dirección: {`${usuarioDetalle.direccion.calle}, ${usuarioDetalle.direccion.numero_casa}, ${usuarioDetalle.direccion.colonia}, ${usuarioDetalle.direccion.codigo_postal}, ${usuarioDetalle.direccion.municipio}, ${usuarioDetalle.direccion.pais}`}</p>
        </Modal>
      )}
      {mostrarModalMacs && (
        <Modal onClose={() => setMostrarModalMacs(false)}>
          <div style={{ position: 'relative', fontSize: '14px' , marginBottom: '10px'}}> 
            <h2>Selecciona un producto para asignar</h2>
            <div className="codigo-buttons-container"> 
              {codigosDisponibles.map((codigo, index) => ( 
                <button
                  className="button-codigo" 
                  key={index}
                  onClick={() => asignarCodigoSeleccionado(codigo)} 
                >
                  {codigo}
                </button>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Usuarios;
